import React from 'react';
import cn from 'classnames';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import styles from './successOverlay.scss';

interface IProps {
  onCloseModalClick: () => void,
  successText: string,
  isSubmitSucceed: boolean,
}

const SuccessOverlay = (props: IProps) => {
  const isVisible = cn(styles.successOverlay, {
    [styles.succeedSubmit]: props.isSubmitSucceed,
  });
  return (
    <div className={ isVisible }>
      <div className={ styles.successSubmitContainer }>
        <div className={ styles.contentWrapper }>
          <h1>Thank you!</h1>
          <p>{ props.successText }</p>
          <Button
            variant={ ButtonVariantType.action }
            onClick={ props.onCloseModalClick }
            title="Close"
            dataTest="closeModalButton"
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessOverlay;
