import React, { useRef, useCallback } from 'react';
import noop from 'lodash/noop';

import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import FormEmailInput from '@/finsight/ui/common/components/EmailInput';
import { Textarea } from '@dealroadshow/uikit';
import UploadFile from '@/finsight/ui/common/components/UploadFile';
import FinsightModal from '@/finsight/ui/common/components/FinsightModal';
import ReCaptcha from '@/Framework/UI/Molecules/Form/ReCaptcha';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import validate from '@/finsight/ui/common/components/FinsightModal/modalValidator';
import { MISSING_SOMETHING_MODAL_FORM, SUBMIT_MISSING_SOMETHING_MODAL_FORM } from './constants';

import styles from './missingSomethingModalForm.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

interface IProps {
  hideModal: () => void,
  submitForm: (values: any, payload: any) => void,
  resetModal: () => void,
  isVisible: boolean,
}

const MissingSomethingModalForm = (
  {
    submitForm,
    resetModal,
    hideModal,
    isVisible,
    ...otherProps
  }: IProps,
) => {
  let resetFinalFormState = noop;
  const inputUploadFile = useRef(null);

  const onCloseClicked = useCallback(
    () => {
      inputUploadFile.current.deleteFile();
      resetFinalFormState();
      hideModal();
      resetModal();
    },
    [resetFinalFormState, resetModal, hideModal],
  );

  const renderFields = useCallback(
    ({ valid, form }, { Field }) => {
      resetFinalFormState = form.restart;
      return (
        <>
          <div className={ styles.headerText }>
            <h1>Missing something? Tell us</h1>
            <p className={ styles.modalDescription }>
              Report a missing deal or ask us a question! All feedback, ideas, and suggestions are welcome.
            </p>
          </div>
          <Field
            name="email"
            type="email"
            className={ styles.inputContainer }
            component={ FormEmailInput }
            dataTest="missingSomethingModalFormEmailInput"
          />
          <Field
            textareaClassName={ styles.textarea }
            placeholder="Please provide comments here"
            name="comments"
            component={ Textarea }
            dataTest="missingSomethingModalFormTextArea"
          />
          <UploadFile
            formName={ MISSING_SOMETHING_MODAL_FORM }
            ref={ inputUploadFile }
          />
          { /* We need the check below because ReCaptcha is suspected in flooding sentry with errors, see FIN-10487 */ }
          { isVisible && (
            <div className={ spacesStyles.mbxl }>
              <Field
                name="recaptcha"
                component={ ReCaptcha }
              />
            </div>
          ) }
          <Button
            dataTest={ SUBMIT_MISSING_SOMETHING_MODAL_FORM }
            type="submit"
            disabled={ !valid }
            variant={ ButtonVariantType.action }
          >
            Submit
          </Button>
          <Button
            dataTest="cancelButton"
            variant={ ButtonVariantType.text }
            onClick={ onCloseClicked }
          >
            Cancel
          </Button>
        </>
      );
    },
    [isVisible, onCloseClicked, resetFinalFormState],
  );

  const onSumbit = useCallback(
    (payload) => submitForm(inputUploadFile.current.getFile(), payload),
    [submitForm],
  );

  return (
    <FinsightModal
      hideOnOverlayClicked
      successText="Your feedback has been submitted."
      // @ts-ignore
      modalDescription="Report a missing deal or ask us a question! All feedback, ideas, and suggestions are welcome."
      modalTitle={ <>Instant Market<br />Color</> }
      onCloseClicked={ onCloseClicked }
      isVisible={ isVisible }
      dataTest="missingSomethingModal"
      { ...otherProps }
    >
      { /* @ts-ignore */ }
      <FinalForm
        dataTest={ MISSING_SOMETHING_MODAL_FORM }
        id={ MISSING_SOMETHING_MODAL_FORM }
        role="form"
        onSubmit={ onSumbit }
        render={ renderFields }
        validate={ validate }
      />
    </FinsightModal>
  );
};

export default MissingSomethingModalForm;
